import { Link } from "@StarberryUtils";
import React, { useState, useEffect } from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
// Images
// import VideoPosterLarge from "../../../images/home/banner_d.jpg"
// import VideoPosterMedium from "../../../images/home/banner_t.jpg"
// import VideoPosterSmall from "../../../images/home/banner_m.jpg"
import FeefoLogo from "../../../images/feefo-logo.svg"
import { GetURL, feefo } from "../../Common/site/functions"
import ContactForm from "../../Forms/contact-form";
import EnquiryForm from "../../Forms/enquiry-form";
import ModalTiny from "react-bootstrap/Modal"
// Style
import './Banner.scss';
import PlayVideo from "../../Play/CustomVideo";
import _ from "lodash";

import { inViewOptions, containerVariants, fadeInFromBottom, contentItemStagger, contentItem } from '../../Utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { isMobileOnly } from "react-device-detect";
const MotionCol = motion(Col)

const HomeBanner = (props) => {
    var page = props?.page;
    var path = props?.path;
    const [avgReview, setAvgReview] = useState(0);
    const [review, setReview] = useState(0);
    const [modalContactformOpen, setContactformOpen] = React.useState(false);
    const [modalEnquiryFormOpen, setEnquiryFormOpen] = React.useState(false);
    const [modalFormTitle, setModalFormTitle] = React.useState("General Enquiry");
    const closeContactformModal = () => {
        setContactformOpen(false);
    }
    const closeEnquiryFormModal = () => {
        setEnquiryFormOpen(false);
    }

    let url_1 = ['']
    let url_2 = ['']
    if (page && page.Header_CTA_1_Link) {
        url_1 = GetURL(page.Header_CTA_1_Link.id)
    }
    if (page && page.Header_CTA_2_Link) {
        url_2 = GetURL(page.Header_CTA_2_Link.id)
    }

    const openForm = (formTitle, formName) => {
        if (formName === "Contact_Form") {
            setContactformOpen(true);
            setModalFormTitle(formTitle)
        }
    }

    useEffect(() => {
        if (_.isEmpty(avgReview)) {
            feefo((review) => {
                if (review) {
                    setAvgReview(review.rating.rating)
                    setReview(review.rating.service.count)
                }
            })
        }
    })

 
    return (
        <React.Fragment>
            <InView {...inViewOptions}>
                {({ ref, inView }) => (
                    <motion.div
                        className="banner-section wrapper-banner-section"
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <Container>
                            {page && <Row>
                                <MotionCol lg="7" className="order-lg-1" variants={contentItemStagger}>
                                    <div className="banner-section-content">
                                        <motion.h1 variants={contentItem}>{page.Title}</motion.h1>
                                        <motion.p variants={contentItem}>{page.Sub_Title}</motion.p>
                                    </div>
                                    <motion.div className="banner-section-btn" variants={contentItem}>

                                        {page.CTA_1_Label && page.CTA_1_Form &&
                                            <Button onClick={() => { openForm(page.CTA_1_Label, page.CTA_1_Form) }} className="btn btn-primary">{page.CTA_1_Label}<i className="icon-arrow"></i></Button>
                                        }

                                        {page.CTA_2_Label && page.CTA_2_Form &&
                                            <Button onClick={() => { openForm(page.CTA_2_Label, page.CTA_2_Form) }} className="btn btn-primary">{page.CTA_2_Label}<i className="icon-arrow"></i></Button>
                                        }

                                        

                                        {page.CTA_1_Label && page.Header_CTA_1_Link && url_1[1] == 'Internal' ?
                                                (url_1[0] === "about/careers") ?
                                                    <a href={`#career-form`} className="btn btn-primary">{page.CTA_1_Label}<i className="icon-arrow"></i></a>
                                                : (path === "/property-services/mortgages/" && url_1[0] === "property-services/mortgages") ? <a href={`https://www.mortgageadvicebureau.com/mortgage-advisers/severn-mortgage-and-protect/meet-the-team`} target="_blank" className="btn btn-primary">{page.CTA_1_Label}<i className="icon-arrow"></i></a> :
                                                    <Link to={`/${url_1[0]}`} className="btn btn-primary">{page.CTA_1_Label}<i className="icon-arrow"></i></Link>
                                            :
                                            page.CTA_1_Label && page.Header_CTA_1_Link && url_1[1] == 'External' ?
                                                <a href={`${url_1[0]}`} target="_blank" className="btn btn-primary">{page.CTA_1_Label}<i className="icon-arrow"></i></a>
                                                : ''
                                        }

                                        {page.CTA_2_Label && page.Header_CTA_2_Link && url_2[1] == 'Internal' ?
                                            <>
                                            <span id="prop-sale-mobile" className="hidden">
                                                <a href="javascript:void(0);" onClick={props.handlerSearchClick} className="btn btn-primary search">{page.CTA_2_Label}<i className="icon-arrow"></i></a>
                                            </span>
                                            <span id="prop-sale-desktop">
                                                <Link to={`/${url_2[0]}`} className="btn btn-primary">{page.CTA_2_Label}<i className="icon-arrow"></i></Link>
                                            </span>
                                            </>
                                            :
                                            page.CTA_2_Label && page.Header_CTA_2_Link && url_2[1] == 'External' ?
                                                <a href={`${url_2[0]}`} target="_blank" className="btn btn-primary">{page.CTA_2_Label}<i className="icon-arrow"></i></a>
                                                : ''
                                        }
                                        {page.Pagename == "Mortgages" &&
                                            <div className="mortgages-btn">
                                                <Button  onClick={() => { setEnquiryFormOpen(true) }}  className="btn btn-primary">Request a mortgage call back<i className="icon-arrow"></i></Button>
                                            </div>
                                        }

                                        {page.Pagename == "Tenants" &&
                                            <div className="tenants-btn">
                                                <Link target="_blank" to={`https://dbroberts.fixflo.com/Auth/Login?returnUrl=%2fDashboard%2fHome%2f%23%2fAgent%2fH[…]f%26%26_%3d1646298914043&ForceLogout=true&LoginReasonInt=1`}>
                                                    <Button className="btn btn-primary">Reporting a maintenance problem<i className="icon-arrow"></i></Button>
                                                </Link>
                                            </div>
                                        }
                                        {page.Pagename == "Auction" &&
                                            <div className="auction-btn">
                                                <Link to={`/videos/auction-faq-s/`}>
                                                    <Button className="btn btn-primary">FAQs<i className="icon-arrow"></i></Button>
                                                </Link>
                                            </div>
                                        }
                                    </motion.div>
                                    <motion.div className="google-rating" variants={contentItem}>
                                        <div><img src={FeefoLogo} alt="Feefo logo" /></div>
                                        {page.Pagename == "Customer Reviews" ?
                                        <p>{avgReview}/5 Rating based on {review} verified reviews </p>
                                        :
                                        <p>{avgReview}/5 Rating based on  <Link to={`/about/reviews`} className="banner-btn-review">{review} verified reviews </Link></p>
                                        }
                                    
                                    </motion.div>
                                </MotionCol>
                                <Col lg="5" className="order-lg-2 img-zoom pr-0">
                                    <motion.div className={`banner-section-img ${page.AutoPlay? "autoplay" : ""}`} variants={fadeInFromBottom}>
                                        <picture>
                                            <source media="(min-width:992px)" srcSet={page.Banner_Image.url} />
                                            <source media="(min-width:768px)" srcSet={page.Banner_Image.url} />
                                            <img className="" src={page.Banner_Image.url} alt={page.Banner_Image.alternativeText} />
                                        </picture>
                                        {page.Banner_Video_URL &&
                                            <PlayVideo 
                                            url={page.Banner_Video_URL} 
                                            icon="icon-play"
                                            class="play-btn"
                                            autoplay={page.AutoPlay}
                                            repeat={page.Repeat}
                                            buttonName="Play" />
                                        }
                                    </motion.div>
                                </Col>
                            </Row>}
                        </Container>
                    </motion.div>
                )}
            </InView>
            <ModalTiny size="lg" show={modalContactformOpen} onHide={closeContactformModal} className="modal-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">{modalFormTitle}</h2></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section"><ContactForm title={modalFormTitle} /></ModalTiny.Body>
            </ModalTiny>
            <ModalTiny size="lg" show={modalEnquiryFormOpen} onHide={closeEnquiryFormModal} className="modal-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Request a mortgage call back</h2></ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section"><EnquiryForm title="Request a mortgage call back" /></ModalTiny.Body>
            </ModalTiny>
        </React.Fragment>
        

    )
}



export default HomeBanner